import React from 'react'
const Logo = () => (

  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='96.995'
    height='16.282'
    viewBox='0 0 96.995 16.282'>
    <path
      id='Path_18'
      d='M-253.78-488.72h-16.89V-498.9h3.353v5.761h11.634a3.006,3.006,0,0,0,1.93-.671,2.047,2.047,0,0,0,.868-1.622l0-3.136a2.452,2.452,0,0,0-.909-1.483,3.016,3.016,0,0,0-2-.643h-14.877V-505h16.889a4.069,4.069,0,0,1,2.992,1.232,3.732,3.732,0,0,1,1.232,2.853v8.139a3.884,3.884,0,0,1-1.228,2.882,4.174,4.174,0,0,1-2.9,1.176Zm-54.326,0h-12.669a4.234,4.234,0,0,1-2.992-1.175A3.886,3.886,0,0,1-325-492.776v-8.169a3.736,3.736,0,0,1,1.232-2.853,4.121,4.121,0,0,1,2.993-1.2h12.669a4.068,4.068,0,0,1,2.992,1.232,3.735,3.735,0,0,1,1.231,2.853v8.139a3.888,3.888,0,0,1-1.231,2.882,4.174,4.174,0,0,1-2.9,1.176Zm-2.015-4.419a3.171,3.171,0,0,0,2.042-.671,2.047,2.047,0,0,0,.866-1.622l0-3.129a2.445,2.445,0,0,0-.907-1.483,3.021,3.021,0,0,0-2-.643h-8.668a3.126,3.126,0,0,0-2,.629,2.088,2.088,0,0,0-.854,1.552v3.132a1.846,1.846,0,0,0,.212.851,2.226,2.226,0,0,0,.587.713,2.924,2.924,0,0,0,.895.489,3.4,3.4,0,0,0,1.133.182Zm78.675,4.419-3.719-6.265h-6.74l-3.8,6.265h-3.411l5.453-9.956h6.487l-1.173-2.016h-4.335l2.434-4.3h3.384l8.865,16.277Zm-45.517,0V-505h3.355v16.277Zm-6.322,0v-11.971l-6.039,11.971h-2.239l-6.067-11.971h-3.414V-505h4.616l6.041,11.242L-284.712-505h4.783v16.275Zm-17.758,0v-10.011h3.353v10.011Z'
      transform='translate(325 505)'
      fill='currentColor'
    />
  </svg>
)

export default Logo
